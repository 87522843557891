import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {
	container,
	gridc,
	grid,
	screen,
	spacingRestricted,
} from "../styles/mixins";
import FluidImagerX from "./elements/FluidImagerX";
import { format } from "date-fns";
import { useStaticQuery, graphql } from "gatsby";
import { MetaSmall } from "./elements/Section";
import trustpilotStars from "../styles/trustpilotStars";
import color from "../styles/colors";
import { EffectFade, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { ReactComponent as TrustpilotLogoLight } from "../images/vectors/trustpilot-logo-light.svg";
import "swiper/swiper.scss";
import "swiper/modules/effect-fade/effect-fade.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";

const eggplant = color("purple");

const Section = styled.section`
	${eggplant};
	background-color: ${color("purple")};
	padding-bottom: 50px;

	@media ${screen("md")} {
		padding-bottom: 100px;
	}

	.SwiperClass {
		position: relative;
		padding-bottom: 30px;

		.swiper-slide {
			opacity: 0;
			visibility: hidden;

			&.swiper-slide-active {
				opacity: 1;
				visibility: visible;
			}
		}

		.swiper-pagination  {
			display: flex;
			justify-content: center;
			gap: 12px;
			bottom: 35px;

			@media ${screen("md")} {
				bottom: 50px;
			}
		}

		.swiper-pagination-bullet {
			margin: 0;
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: ${color("purple")};
			opacity: 0.3;
			transition: .3s ease color;

			&.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}

	}
`;

const ContainerEl = styled.div`
	${container()}
`;

const Wrapper = styled.div`
	overflow: hidden;
	background-color: ${color("blue")};
	border-radius: 20px;
	${gridc(3, 44)}

	@media ${screen("md")} {
		${grid(48)}
		border-radius: 15px 15px 150px 15px;
	}
`;

const TrustpilotWrapper = styled.div`
	position: relative;
	padding: 45px;
	background-color: ${color("purple")};
	border-bottom-right-radius: 90px;
	overflow: hidden;

	@media ${screen("md")} {
		${gridc(1, 27)}
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 100px 40px;
		border-bottom-right-radius: 0;
	}

	@media ${screen("lg")} {
		padding: 180px 100px;
	}
`;

const SwiperWrapper = styled.div`

	@media ${screen("md")} {
		${gridc(28, 45)}
	}
`;

const Testimonial = styled.div`
	padding: 1.7em;

	@media ${screen("md")} {
		padding: 3.4em 2.2em;
	}

	@media ${screen("lg")} {
		padding: 4em 3.6em;
	}
`;

const TestimonialHeading = styled.h3`
	font-size: 40px;
	line-height: 1.1;
  letter-spacing: -0.01em;
	font-weight: 500;
	color: ${color("green")};
  ${spacingRestricted("margin-bottom", "s")};

	@media ${screen("lg")} {
		font-size: 45px;
	}
`;

const TestimonialText = styled.div`
	font-size: 17px;
	line-height: 1.3;
	${spacingRestricted("margin-bottom", "m")};

	@media ${screen("md")} {
		font-size: 18px;
		${spacingRestricted("margin-bottom", "l")};
	}
`;

const TestimonialCite = styled.p`
	line-height: 1.3;
	margin-bottom: 6px;

	@media ${screen("md")} {
		font-size: 20px;
	}

	@media ${screen("md")} {
		font-size: 22px;
	}
`;

const TestimonialDate = styled.p`
	line-height: 1.3;

	@media ${screen("lg")} {
		font-size: 18px;
	}
`;

const HeroImage = styled(FluidImagerX)`
	position: absolute !important;
	top: 0;
	left: 0;
	opacity: 0.3;
	width: 100%;
	height: 100%;
`;

const Trustpilot = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: color("white");
	gap: 6px;
	z-index: 1;

	svg {
		width: auto;
	}

	@media ${screen("md")} {
		margin: 0;
	}
`;

const TrustpilotLogoWrapper = styled.div`
	margin-bottom: 25px;

	svg {
		max-width: 200px;
		width: 100%;

		@media ${screen("md")} {
			max-width: 380px;
		}
	}
`;

const TrustpilotStarsWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;

	svg {
		width: 109px;
	}
`;

const TrustpilotText = styled(MetaSmall)`
	color: ${color("white")};
	font-size: 15px;
	text-align: center;

	a {
		border-bottom: 1px solid;
		transition: all 0.2s;

		&:hover {
			opacity: 0.8;
		}
	}
`;

const TrustpilotSection = ({
	colorTheme = "light",
	data
}) => {
	const testimonials = data[0]?.testimonials;
	const backgroundImage = data[0]?.backgroundImage[0];
	const trustpilotData = useStaticQuery(graphql`
		query TrustpilotSectionQuery {
			allTrustPilotPublicBusinessUnit {
				nodes {
					numberOfReviews {
						total
					}
					score {
						stars
						trustScore
					}
				}
			}
		}
	`);

	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return `<span class=${className}></span>`
		},
	};

	const businessUnitData =
	trustpilotData.allTrustPilotPublicBusinessUnit.nodes[0];

	const { el: TrustpilotStars, text: trustpilotTrustText } = trustpilotStars(
		businessUnitData?.score.stars
	);

	return data?.length ? (
		<Section>
			<ContainerEl>
				<Wrapper>
					<TrustpilotWrapper>
						<HeroImage
							image={backgroundImage}
							sizes="(min-width: 2200px) 1520px, (min-width: 720px) 50vw, 100vw"
							ratio={1044 / 1017}
							loading="eager"
							alt={
								backgroundImage
									? backgroundImage.title
									: null
							}
							gatsbyImage={false}
						/>
						<Trustpilot>
							<TrustpilotLogoWrapper>
								<a
									href="https://www.trustpilot.com/review/thelendingpeople.co.nz"
									target="_blank"
									aria-label="Trustpilot">
									<TrustpilotLogoLight />
								</a>
							</TrustpilotLogoWrapper>
							<TrustpilotText>
								{trustpilotTrustText} -{" "}
								<a
									href="https://www.trustpilot.com/review/thelendingpeople.co.nz"
									target="_blank">
									Based on{" "}
									{businessUnitData.numberOfReviews.total} reviews
								</a>
								<TrustpilotStarsWrapper>{TrustpilotStars}</TrustpilotStarsWrapper>
							</TrustpilotText>
						</Trustpilot>
					</TrustpilotWrapper>
					<SwiperWrapper>
						<Swiper
							effect={"fade"}
							fadeEffect={{ crossFade: true }}
							slidesPerView={1}
							speed={500}
							autoplay={{
								delay: 8000,
								pauseOnMouseEnter: true,
								waitForTransition: true
							}}
							loop={true}
							className="SwiperClass"
							centerInsufficientSlides={true}
							modules={[EffectFade, Pagination, Autoplay]}
							pagination={pagination}>
							{testimonials.map((item, idx) => (
								<SwiperSlide key={idx}>
									<Testimonial>
										<TestimonialHeading>{item.title}</TestimonialHeading>
										<TestimonialText dangerouslySetInnerHTML={{ __html: item.richtext}} />
										<TestimonialCite>{item.cite}</TestimonialCite>
										<TestimonialDate>{format(new Date(item.date), "MMM d, yyyy")}</TestimonialDate>
									</Testimonial>
								</SwiperSlide>
							))}
						</Swiper>
					</SwiperWrapper>
				</Wrapper>
			</ContainerEl>
		</Section>
	) : null;
};

TrustpilotSection.propTypes = {
	colorTheme: PropTypes.string,
};

TrustpilotSection.defaultProps = {
	colorTheme: "light",
};

export default TrustpilotSection;